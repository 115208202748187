import {
  httpClientIntegration,
  reportingObserverIntegration,
  captureConsoleIntegration
} from '@sentry/integrations'
import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import { withScope } from '@sentry/vue'
import type { Router } from 'vue-router'
import { defineNuxtPlugin } from '#app'
import { getConfig } from './i18n'


export default defineNuxtPlugin({
  parallel: true,
  setup: async (nuxtApp) => {
    if (
      typeof window === 'undefined' ||
      !['true', true].includes(nuxtApp.$config.public.SENTRY_ENABLED)
    ) {
      return {
        provide: {
          sentrySetContext: (
            _name: string,
            _context: {
              [key: string]: any
            } | null,
          ) => {},
          sentrySetUser: (_user: User | null) => {},
          sentrySetTag: (_key: string, _value: Primitive) => {},
          sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
          sentryCaptureException: (_exception: any, _captureContext?: CaptureContext) => {},
        },
      }
    }

    const conf = await getConfig();
    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.SENTRY_DSN || '',
      release: nuxtApp.$config.public.SENTRY_RELEASE|| '',
      environment: conf.Env || 'unknown',
      integrations: [
         Sentry.browserTracingIntegration({
          router :nuxtApp.$router as Router,
          routeLabel: 'path',
        }),
        captureConsoleIntegration({
          levels: ['error']
        }),
         httpClientIntegration(),
         reportingObserverIntegration(),
         Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maxReplayDuration : 60 * 1000,
          minReplayDuration : 5 * 1000,
          networkDetailAllowUrls: [/sciensano.be/, /darwinproject.be/],
        }),
      ],
      tracePropagationTargets: <any>nuxtApp.$config.public.SENTRY_TRACE_PROPAGATION_TARGET || [],
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    })

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context)
        Sentry.captureException(err)
      })
    }

    nuxtApp.hook('app:error', (err) => {
      Sentry.captureException(err)
    })
    

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    }
  },
})