<template>
  <div>
    <v-app>
      <s-navigation-app-bar :menu="false" @toggle="drawer.toggle()">
        <template v-slot:aftermenu>
          <s-translation-switcher />
        </template>
      </s-navigation-app-bar>

      <v-main>
        <v-container fluid style="text-align: center">
          <h1 v-if="error.statusCode === 404">
            {{ pageNotFound }}
          </h1>
          <h1 v-else>
            {{ otherError }}
          </h1>
          <br />
          <v-btn color="primary" :to="localePath('/')"> Home page </v-btn>
        </v-container>
      </v-main>
      <s-navigation-footer />
    </v-app>
  </div>
</template>

<script>
export default {
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: "404 Not Found",
      otherError: "An error occurred",
    };
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
