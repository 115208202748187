<template>
  <div v-if="loading" class="loader">
    <img
      src="/images/logo_bird_only.svg"
      class="logo"
      width="70"
      height="70"
      alt="logo of the website"
    />
    <div id="circlloader"></div>
  </div>
</template>
<style scoped>

.loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,1);
    z-index: 1007;
}
</style>
<style lang="scss">
@import "~/assets/variables.scss";

.logo {
  display: flex;
  height: 40px;
  width: 70px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.95);
  }
}
#circlloader {
  display: block;
  left: 50%;
  top: 40%;
  width: 150px;
  height: 150px;
  margin: 0px 0 0 -112px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $tertiary;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#circlloader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $primary;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#circlloader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $secondary;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

var app = null;
onMounted(() => {
  app = document.querySelector("body");
});

watch(
  () => props.loading,
  (val) => {
    if (app) {
      if (val) {
        app.style.height = "100vh"
        app.style.overflow = "hidden";
      }
      else {
        app.style.overflow = "visible";
        app.style.height = "auto";
      }
    }
  }
);
</script>
