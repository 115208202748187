
var baseUrl = "";
async function _getBaseUrl(){
    if(!baseUrl){
        await $fetch("/api.p.json").then(x => {
            baseUrl =  x.baseUrl;  
        })
    }

    return baseUrl;
} 


export const getBaseUrl = _getBaseUrl