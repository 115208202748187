
const loading = ref(0)


export const useLoader = (increment) => {
    const incr = () =>  {
        ++loading.value
    }

    const decr = () => {
        if(loading.value > 0)
            --loading.value
    }

    increment && incr()

    return {
        loadingCounter : loading,
        isLoading : computed(() => loading.value > 0),
        incr,
        decr,
        reset : () => loading.value = 0
       

    }
}