//import { loadLanguageAsync } from "../.nuxt/nuxt-i18n/plugin.utils"
var config = null;

export async function getConfig() {
  if (config) {
    return config;
  }
  if (typeof window != "undefined") {
    var response = await fetch("/api.p.json");
     config = await response.json();
     return config;
  } else {
    return {};
  }
}

export async function  getLanguages() {
  var { translations,translation_key } = await getConfig();
  var url = `${translations}project/${translation_key}/languages`;
  if (typeof fetch == "undefined") {
    return ["key"];
  }
  try {
    var response = await fetch(url);
    return await response.json();
  } catch(e){
    console.error(e);
  }
  return ["en"]
  
}

export default defineNuxtPlugin(async function (context) {
    const { $i18n,i18nExtention} = context;
    const { nuxtI18nInternalOptions  ,nuxtI18nOptions, loadInitialMessages , localeCodes, localeLoaders} = i18nExtention
     $i18n.loadInitialMessages=loadInitialMessages
    const rawlanguages = await getLanguages()
    var { showKeys } = await getConfig();
    if(showKeys == true || showKeys === 'true')
      rawlanguages.push("key")
    
    const locales = []
  
    for (const locale of rawlanguages) {
      locales.push({
        code: locale,
        file: `load.js`
      })
    }
    nuxtI18nInternalOptions.normalizedLocales = locales;
    nuxtI18nOptions.locales = locales;
    const keyConfig = localeLoaders["key"][0]
    if(!keyConfig) throw("Missing key language from default configuration") 
    localeCodes.splice(0,localeCodes.length)
    rawlanguages.forEach(lang => {
      localeLoaders[lang] =[{ ...keyConfig, key : lang}]
      localeCodes.push(lang)
    })
    $i18n.locales = ref(locales);
    $i18n.localeProperties = ref(rawlanguages.map(x => ({ code : x})))
    $i18n.localeCodes = ref(rawlanguages)
    var language =  $i18n.getLocaleCookie() || $i18n.getBrowserLocale() || $i18n.defaultLocale;
    if(rawlanguages.indexOf(language) == -1){
        language =  rawlanguages[0] ;
    }
    $i18n.defaultLocale =language || rawlanguages[0]
    $i18n.setLocale($i18n.defaultLocale)
    //await context.i18nOptions.loadLanguageAsync(context, language)
});