<template>
  <v-app-bar color="light-green lighten-4" image="/images/profile-bg-revert.png">
   
  
    <template v-slot:prepend>
      <v-app-bar-nav-icon  v-if="menu" @click.stop="emit('toggle')" class="hidden-lg-and-up text-white">
      </v-app-bar-nav-icon>
    </template>

    <v-app-bar-title>
      <router-link :to="localePath('/')">
        <v-img
          src="/images/sciensano_logo_white.svg"
          class="float-left mr-3"
          width="100"
          height="50"
          alt="logo of the website"
          contain
        ></v-img>
      </router-link>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <slot name="aftermenu"></slot>
  </v-app-bar>
</template>
  
<script setup>

var emit = defineEmits(["toggle"])

defineProps({
  logo: {
    default: undefined,
    type: String,
  },
  menu : {
    default :true,
    type : Boolean
  },
  postmenuclass: {
    default: "",
    type: String,
  },
  showMenu: {
    default: false,
    type: Boolean,
  },
  clippedleft: {
    default: false,
    type: Boolean,
  },
  clippedright: {
    default: false,
    type: Boolean,
  },
});
</script>

  
<style scoped>
.icon-brand {
  height: 45px;
  width: 100px;
  margin-right: 10px;
  float: left;
}
.platform_name {
  text-transform: uppercase;
  margin-left: 10px;
}

.flip :deep(.v-toolbar__image) {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
</style>
  