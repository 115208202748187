import revive_payload_client_4sVQNw7RlN from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/agent/_work/45/s/Web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "C:/agent/_work/45/s/Web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "C:/agent/_work/45/s/Web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chart_hiUwyzYZgo from "C:/agent/_work/45/s/Web/plugins/chart.js";
import i18n_sVHQBgnb3t from "C:/agent/_work/45/s/Web/plugins/i18n.js";
import sentry_3AyO8nEfhE from "C:/agent/_work/45/s/Web/plugins/sentry.ts";
import toast_Zl2rZYKMaV from "C:/agent/_work/45/s/Web/plugins/toast.js";
import vuetify_8NhHJigKc1 from "C:/agent/_work/45/s/Web/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  chart_hiUwyzYZgo,
  i18n_sVHQBgnb3t,
  sentry_3AyO8nEfhE,
  toast_Zl2rZYKMaV,
  vuetify_8NhHJigKc1
]