import { createVuetify } from "vuetify";
import colors from "vuetify/lib/util/colors";

import * as components from "vuetify/components";
import { vMaska } from "maska";
import draggable from 'vuedraggable';
import LuxonAdapter from "@date-io/luxon"
export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: false,
    components: components,
    theme: {
      defaultTheme: "light",
      dark: false,
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#69aa41",
            secondary: "#becd2d",
            accent: "#376437",
            info: colors.teal.lighten1,
            warning: colors.amber.base,
            error: colors.deepOrange.accent4,
            success: colors.green.accent3,
          },
        },
      },
    },
  });

  nuxtApp.vueApp.directive("maska", vMaska);
  nuxtApp.vueApp.component("draggable", draggable);
  nuxtApp.vueApp.use(vuetify);
});
