import { default as index2zJvcwdh9eMeta } from "C:/agent/_work/45/s/Web/pages/@connected/administration/users/assigned/index.vue?macro=true";
import { default as indexKDPuUBa5WiMeta } from "C:/agent/_work/45/s/Web/pages/@connected/administration/users/hd/index.vue?macro=true";
import { default as indexZjTGSlJVQmMeta } from "C:/agent/_work/45/s/Web/pages/@connected/administration/users/new/index.vue?macro=true";
import { default as _91month_93jRYw2KZ5SxMeta } from "C:/agent/_work/45/s/Web/pages/@connected/cases/[year]/[month].vue?macro=true";
import { default as allcAyCA0V8mgMeta } from "C:/agent/_work/45/s/Web/pages/@connected/cases/all.vue?macro=true";
import { default as by_45yearkgnWP98EIhMeta } from "C:/agent/_work/45/s/Web/pages/@connected/cases/by-year.vue?macro=true";
import { default as by_45userc5LfGN70gAMeta } from "C:/agent/_work/45/s/Web/pages/@connected/dashboard/by-user.vue?macro=true";
import { default as indexvo5rOEvfJxMeta } from "C:/agent/_work/45/s/Web/pages/@connected/dashboard/index.vue?macro=true";
import { default as zerogBewTAoQumMeta } from "C:/agent/_work/45/s/Web/pages/@connected/dashboard/zero.vue?macro=true";
import { default as index9NZsMICHM0Meta } from "C:/agent/_work/45/s/Web/pages/@connected/news/index.vue?macro=true";
import { default as no_45rightsAQQsaAU2LlMeta } from "C:/agent/_work/45/s/Web/pages/@connected/no-rights.vue?macro=true";
import { default as indexRmfsG49EO9Meta } from "C:/agent/_work/45/s/Web/pages/@connected/register/multiple/index.vue?macro=true";
import { default as index5OCWOZSXYcMeta } from "C:/agent/_work/45/s/Web/pages/@connected/register/zero/index.vue?macro=true";
import { default as _91id_93bIgaXcuYlxMeta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/[id].vue?macro=true";
import { default as addPomsVA0KBfMeta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/add.vue?macro=true";
import { default as index3uK4iQBOF6Meta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/index.vue?macro=true";
import { default as _91id_936HEL6Yf1TrMeta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/[id].vue?macro=true";
import { default as addgGTm5YZzR7Meta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/add.vue?macro=true";
import { default as index9jbp7l2rjuMeta } from "C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/index.vue?macro=true";
import { default as indexB6N1DwiM1LMeta } from "C:/agent/_work/45/s/Web/pages/@public/index.vue?macro=true";
import { default as loginMe3fZsWQaBMeta } from "C:/agent/_work/45/s/Web/pages/@public/login.vue?macro=true";
import { default as logoutPVEdXSJfxwMeta } from "C:/agent/_work/45/s/Web/pages/@public/logout.vue?macro=true";
import { default as signin_45oidcrYV6LoB5o2Meta } from "C:/agent/_work/45/s/Web/pages/@public/signin-oidc.vue?macro=true";
import { default as signout_45callback_45oidcmmijOI9akEMeta } from "C:/agent/_work/45/s/Web/pages/@public/signout-callback-oidc.vue?macro=true";
import { default as silent_45renew_45oidc6YyM3pabu5Meta } from "C:/agent/_work/45/s/Web/pages/@public/silent-renew-oidc.vue?macro=true";
import { default as zerocaseQRgue8RDlrMeta } from "C:/agent/_work/45/s/Web/pages/@public/zerocase.vue?macro=true";
export default [
  {
    name: index2zJvcwdh9eMeta?.name ?? "@connected-administration-users-assigned",
    path: index2zJvcwdh9eMeta?.path ?? "/administration/users/assigned",
    meta: { ...(index2zJvcwdh9eMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: index2zJvcwdh9eMeta?.alias || [],
    redirect: index2zJvcwdh9eMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/administration/users/assigned/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDPuUBa5WiMeta?.name ?? "@connected-administration-users-hd",
    path: indexKDPuUBa5WiMeta?.path ?? "/administration/users/hd",
    meta: { ...(indexKDPuUBa5WiMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: indexKDPuUBa5WiMeta?.alias || [],
    redirect: indexKDPuUBa5WiMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/administration/users/hd/index.vue").then(m => m.default || m)
  },
  {
    name: indexZjTGSlJVQmMeta?.name ?? "@connected-administration-users-new",
    path: indexZjTGSlJVQmMeta?.path ?? "/administration/users/new",
    meta: { ...(indexZjTGSlJVQmMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: indexZjTGSlJVQmMeta?.alias || [],
    redirect: indexZjTGSlJVQmMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/administration/users/new/index.vue").then(m => m.default || m)
  },
  {
    name: _91month_93jRYw2KZ5SxMeta?.name ?? "@connected-cases-year-month",
    path: _91month_93jRYw2KZ5SxMeta?.path ?? "/cases/:year()/:month()",
    meta: { ...(_91month_93jRYw2KZ5SxMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: _91month_93jRYw2KZ5SxMeta?.alias || [],
    redirect: _91month_93jRYw2KZ5SxMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/cases/[year]/[month].vue").then(m => m.default || m)
  },
  {
    name: allcAyCA0V8mgMeta?.name ?? "@connected-cases-all",
    path: allcAyCA0V8mgMeta?.path ?? "/cases/all",
    meta: { ...(allcAyCA0V8mgMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: allcAyCA0V8mgMeta?.alias || [],
    redirect: allcAyCA0V8mgMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/cases/all.vue").then(m => m.default || m)
  },
  {
    name: by_45yearkgnWP98EIhMeta?.name ?? "@connected-cases-by-year",
    path: by_45yearkgnWP98EIhMeta?.path ?? "/cases/by-year",
    meta: { ...(by_45yearkgnWP98EIhMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: by_45yearkgnWP98EIhMeta?.alias || [],
    redirect: by_45yearkgnWP98EIhMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/cases/by-year.vue").then(m => m.default || m)
  },
  {
    name: by_45userc5LfGN70gAMeta?.name ?? "@connected-dashboard-by-user",
    path: by_45userc5LfGN70gAMeta?.path ?? "/dashboard/by-user",
    meta: { ...(by_45userc5LfGN70gAMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: by_45userc5LfGN70gAMeta?.alias || [],
    redirect: by_45userc5LfGN70gAMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/dashboard/by-user.vue").then(m => m.default || m)
  },
  {
    name: indexvo5rOEvfJxMeta?.name ?? "@connected-dashboard",
    path: indexvo5rOEvfJxMeta?.path ?? "/dashboard",
    meta: { ...(indexvo5rOEvfJxMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: indexvo5rOEvfJxMeta?.alias || [],
    redirect: indexvo5rOEvfJxMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: zerogBewTAoQumMeta?.name ?? "@connected-dashboard-zero",
    path: zerogBewTAoQumMeta?.path ?? "/dashboard/zero",
    meta: { ...(zerogBewTAoQumMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: zerogBewTAoQumMeta?.alias || [],
    redirect: zerogBewTAoQumMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/dashboard/zero.vue").then(m => m.default || m)
  },
  {
    name: index9NZsMICHM0Meta?.name ?? "@connected-news",
    path: index9NZsMICHM0Meta?.path ?? "/news",
    meta: { ...(index9NZsMICHM0Meta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: index9NZsMICHM0Meta?.alias || [],
    redirect: index9NZsMICHM0Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/news/index.vue").then(m => m.default || m)
  },
  {
    name: no_45rightsAQQsaAU2LlMeta?.name ?? "@connected-no-rights",
    path: no_45rightsAQQsaAU2LlMeta?.path ?? "/no-rights",
    meta: { ...(no_45rightsAQQsaAU2LlMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: no_45rightsAQQsaAU2LlMeta?.alias || [],
    redirect: no_45rightsAQQsaAU2LlMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/no-rights.vue").then(m => m.default || m)
  },
  {
    name: indexRmfsG49EO9Meta?.name ?? "@connected-register-multiple",
    path: indexRmfsG49EO9Meta?.path ?? "/register/multiple",
    meta: { ...(indexRmfsG49EO9Meta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: indexRmfsG49EO9Meta?.alias || [],
    redirect: indexRmfsG49EO9Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/register/multiple/index.vue").then(m => m.default || m)
  },
  {
    name: index5OCWOZSXYcMeta?.name ?? "@connected-register-zero",
    path: index5OCWOZSXYcMeta?.path ?? "/register/zero",
    meta: { ...(index5OCWOZSXYcMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: index5OCWOZSXYcMeta?.alias || [],
    redirect: index5OCWOZSXYcMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/register/zero/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bIgaXcuYlxMeta?.name ?? "@connected-reminder-eid-id",
    path: _91id_93bIgaXcuYlxMeta?.path ?? "/reminder/eid/:id()",
    meta: { ...(_91id_93bIgaXcuYlxMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: _91id_93bIgaXcuYlxMeta?.alias || [],
    redirect: _91id_93bIgaXcuYlxMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/[id].vue").then(m => m.default || m)
  },
  {
    name: addPomsVA0KBfMeta?.name ?? "@connected-reminder-eid-add",
    path: addPomsVA0KBfMeta?.path ?? "/reminder/eid/add",
    meta: { ...(addPomsVA0KBfMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: addPomsVA0KBfMeta?.alias || [],
    redirect: addPomsVA0KBfMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/add.vue").then(m => m.default || m)
  },
  {
    name: index3uK4iQBOF6Meta?.name ?? "@connected-reminder-eid",
    path: index3uK4iQBOF6Meta?.path ?? "/reminder/eid",
    meta: { ...(index3uK4iQBOF6Meta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: index3uK4iQBOF6Meta?.alias || [],
    redirect: index3uK4iQBOF6Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/eid/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936HEL6Yf1TrMeta?.name ?? "@connected-reminder-hd-id",
    path: _91id_936HEL6Yf1TrMeta?.path ?? "/reminder/hd/:id()",
    meta: { ...(_91id_936HEL6Yf1TrMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: _91id_936HEL6Yf1TrMeta?.alias || [],
    redirect: _91id_936HEL6Yf1TrMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/[id].vue").then(m => m.default || m)
  },
  {
    name: addgGTm5YZzR7Meta?.name ?? "@connected-reminder-hd-add",
    path: addgGTm5YZzR7Meta?.path ?? "/reminder/hd/add",
    meta: { ...(addgGTm5YZzR7Meta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: addgGTm5YZzR7Meta?.alias || [],
    redirect: addgGTm5YZzR7Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/add.vue").then(m => m.default || m)
  },
  {
    name: index9jbp7l2rjuMeta?.name ?? "@connected-reminder-hd",
    path: index9jbp7l2rjuMeta?.path ?? "/reminder/hd",
    meta: { ...(index9jbp7l2rjuMeta || {}), ...{"layout":"connected","middleware":["auth"],"@connected":true} },
    alias: index9jbp7l2rjuMeta?.alias || [],
    redirect: index9jbp7l2rjuMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@connected/reminder/hd/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6N1DwiM1LMeta?.name ?? "@public",
    path: indexB6N1DwiM1LMeta?.path ?? "/",
    meta: { ...(indexB6N1DwiM1LMeta || {}), ...{"@public":true} },
    alias: indexB6N1DwiM1LMeta?.alias || [],
    redirect: indexB6N1DwiM1LMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/index.vue").then(m => m.default || m)
  },
  {
    name: loginMe3fZsWQaBMeta?.name ?? "@public-login",
    path: loginMe3fZsWQaBMeta?.path ?? "/login",
    meta: { ...(loginMe3fZsWQaBMeta || {}), ...{"@public":true} },
    alias: loginMe3fZsWQaBMeta?.alias || [],
    redirect: loginMe3fZsWQaBMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/login.vue").then(m => m.default || m)
  },
  {
    name: logoutPVEdXSJfxwMeta?.name ?? "@public-logout",
    path: logoutPVEdXSJfxwMeta?.path ?? "/logout",
    meta: { ...(logoutPVEdXSJfxwMeta || {}), ...{"@public":true} },
    alias: logoutPVEdXSJfxwMeta?.alias || [],
    redirect: logoutPVEdXSJfxwMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/logout.vue").then(m => m.default || m)
  },
  {
    name: signin_45oidcrYV6LoB5o2Meta?.name ?? "@public-signin-oidc",
    path: signin_45oidcrYV6LoB5o2Meta?.path ?? "/signin-oidc",
    meta: { ...(signin_45oidcrYV6LoB5o2Meta || {}), ...{"@public":true} },
    alias: signin_45oidcrYV6LoB5o2Meta?.alias || [],
    redirect: signin_45oidcrYV6LoB5o2Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/signin-oidc.vue").then(m => m.default || m)
  },
  {
    name: signout_45callback_45oidcmmijOI9akEMeta?.name ?? "@public-signout-callback-oidc",
    path: signout_45callback_45oidcmmijOI9akEMeta?.path ?? "/signout-callback-oidc",
    meta: { ...(signout_45callback_45oidcmmijOI9akEMeta || {}), ...{"@public":true} },
    alias: signout_45callback_45oidcmmijOI9akEMeta?.alias || [],
    redirect: signout_45callback_45oidcmmijOI9akEMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/signout-callback-oidc.vue").then(m => m.default || m)
  },
  {
    name: silent_45renew_45oidc6YyM3pabu5Meta?.name ?? "@public-silent-renew-oidc",
    path: silent_45renew_45oidc6YyM3pabu5Meta?.path ?? "/silent-renew-oidc",
    meta: { ...(silent_45renew_45oidc6YyM3pabu5Meta || {}), ...{"@public":true} },
    alias: silent_45renew_45oidc6YyM3pabu5Meta?.alias || [],
    redirect: silent_45renew_45oidc6YyM3pabu5Meta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/silent-renew-oidc.vue").then(m => m.default || m)
  },
  {
    name: zerocaseQRgue8RDlrMeta?.name ?? "@public-zerocase",
    path: zerocaseQRgue8RDlrMeta?.path ?? "/zerocase",
    meta: { ...(zerocaseQRgue8RDlrMeta || {}), ...{"@public":true} },
    alias: zerocaseQRgue8RDlrMeta?.alias || [],
    redirect: zerocaseQRgue8RDlrMeta?.redirect,
    component: () => import("C:/agent/_work/45/s/Web/pages/@public/zerocase.vue").then(m => m.default || m)
  }
]