import { UserManager, WebStorageStateStore } from "oidc-client-ts";

export const currentUser = ref({});
const currentProfile = ref({});
const connected = ref(false);
var config, loading, initial, userManager;

export const useAuth = () => {
  const route = useRoute();
  const { error } = $toast();
  var baseUrl = getBaseUrl();
  const { $i18n,  $sentrySetUser } = useNuxtApp();
  async function GetConfig() {
    if (!config) {
      var response = await fetch("/identity.p.json");
      config = await response.json();
      config.redirect_uri = location.origin + config.redirect_uri;
      config.silent_redirect_uri = location.origin + config.silent_redirect_uri;
      config.post_logout_redirect_uri =
        location.origin + config.post_logout_redirect_uri;

      const settings = Object.assign(
        {
          userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        },
        config
      );
      userManager = new UserManager(settings);
      initial = new Promise(async (resolve) => {
        try {
          const user = await userManager.getUser();
          await successLogin(user, true);
        } catch (e) {
          console.error(e);
        } finally {
          resolve();
        }
      });

   

      userManager.events.addUserLoaded(async function (e) {
        const user = await userManager.getUser();
        await successLogin(user, true);
      });
    
    
    }

    return config;
  }

  function setUser(user, profile) {
    if (user) {
      var d = new Date(0);
      d.setUTCSeconds(user.profile.auth_time);
      var exp = new Date(0);
      exp.setUTCSeconds(user.expires_at);
      user.auth_date = d;
      user.exp_date = exp;

      $sentrySetUser({
        id: user.profile.sub,
        username: user.profile.preferred_username,
        email: user.profile.email,
        role: profile.role,
      })
      currentUser.value = user;
      connected.value = true;
    }
    if (profile) {
      currentProfile.value = profile;
    }
  }

  async function successLogin(user, skip) {
    if (user) {
      try {

        const profile = await $fetch("user/profile", {
          baseURL: await baseUrl,
          method: "get",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        setUser(user, profile);
      } catch (resp) {
        if (resp.response && resp.response.status == 403) {
          setUser(user, {});
        } else if (!resp.response || resp.response.status != 401) {
          if (!skip) error("An internal error occured");
        }
      }
    }
  }

  function setUserLogout() {
    $sentrySetUser(null);
    currentUser.value = {};
    currentProfile.value = {};
    connected.value = false;
  }

  loading = GetConfig();

  var functions = {
    async signinRedirectCallback() {
      const user = await userManager.signinRedirectCallback();
      await successLogin(user);
      return user;
    },
    signinSilentCallback() {
      return userManager.signinSilentCallback();
    },
    login(to) {
      to = to || route.query.to;
      return userManager.signinRedirect({ state : to ? { state: to } : undefined , extraQueryParams : { lang : $i18n.locale.value}});
    },
    relogin(to) {
      return userManager.signinRedirect({ state: to, prompt: "login" });
    },
    async logout() {
      await loading;
      return userManager.signoutRedirect();
    },
    querySessionStatus() {
      return userManager.querySessionStatus();
    },
    getAccessToken() {
      return userManager.getUser().then((data) => {
        return data ? data.access_token : "";
      });
    },
    signoutRedirectCallback() {
      return userManager.signoutRedirectCallback().then(() => {
        setUserLogout();
      });
    },
    isConnected() {
      return connected;
    },
  };

  return {
    initdone: async function () {
      await loading;
      await initial;
    },
    connected,
    hasRight: function (item) {
      if (typeof item === "string" || Array.isArray(item))
        item = { right: item };

      if(!item.right)
          return true;

      var role =  (!currentProfile.value?.role ? "" : currentProfile.value?.role) || "";
      return item.right.includes(role);
    },
    user: currentUser,
    connectedAndAllowed: computed(
      () => currentProfile.value?.role && connected.value
    ),
    fullName: computed(() => {
      return currentUser.value?.profile
        ? currentUser.value.profile.given_name +
            " " +
            currentUser.value.profile.family_name
        : "";
    }),
    profile: currentProfile,
    hasRole : (role) => currentProfile.value?.role == role,
    hasAnyOfRole : (role) => role.indexOf(currentProfile.value?.role) > -1,
    role: computed(() => currentProfile.value?.role || ""),
    ...functions,
  };
};
