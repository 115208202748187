import { useToast } from "vue-toastification";
import { useDate } from "vuetify";
import { DateTime } from 'luxon';

import LuxonAdapter from "@date-io/luxon";
const luxon = new LuxonAdapter({ locale: "fr" });

const debounce = (fn, delay = 1000) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, args), delay);
  };
};

export const useDebounceFunc = (fn, delay) => {
  return debounce(fn, delay);
};

export const useDebounce = () => {
  return {
    debounce,
  };
};


export const formatDate = (d) => {
  if (!d) return "";
  const { format, parseISO } = luxon;
  return format(parseISO(d), "keyboardDateTime");
};

export const formatDateOnly = (d) => {
  if (!d) return "";
  const { format, parseISO } = luxon;
  return format(parseISO(d), "keyboardDate");
};

export const formatDateOnlyFunc = () => {
  const { format, parseISO } = luxon;
  return (d) => {
    if (!d) return "";
    return format(parseISO(d), "keyboardDate");
  };
};

export const toCap = (str) => {
  if (!str) return "";

  return str[0].toUpperCase() + str.substring(1).toLowerCase();
};

export const $params = () => {
  var router = useRouter();
  return router?.currentRoute?.value?.params || {};
};
export const $ttoast = () => {
  const { t } = useI18n();
  const toast = useToast();
  return {
    $success: (message) => toast.success(t(message)),
    $error: (message) => toast.error(t(message)),
    $warning: (message) => toast.warning(t(message)),
    $info: (message) => toast.info(t(message)),
  };
};

export const $toast = () => {
  const toast = useToast();
  return {
    success: toast.success,
    error: toast.error,
    warning: toast.warning,
    info: toast.info,
  };
};

export function isUUID(str) {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return pattern.test(str);
};

export const $downloadBlob = (blob, fileName) => {
  var isIE = false || !!document.documentMode;
  if (isIE) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    var url = window.URL || window.webkitURL;
    var link = url.createObjectURL(blob);
    var a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const formatToDateTime = (date) => {
  try {
    if (!date) return null;
    const [year, month, day] = date.substr(0, 10).split("-");
    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
  } catch (e) {
    console.error(e);
    return date;
  }
};

export const isoDateTimeToCustomFormat = (date) => {
  try {
    if (!date) return null;
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    const localDate = new Date(new Date(date) - userTimezoneOffset);
    const day = String(localDate.getDate()).padStart(2, "0");
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const year = localDate.getFullYear();
    const hour = String(localDate.getHours()).padStart(2, "0");
    const minute = String(localDate.getMinutes()).padStart(2, "0");
    const second = String(localDate.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  } catch (e) {
    return date;
  }
};
