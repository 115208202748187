<template>
    <v-menu :key="$i18n.locales.length" compact>
      <template v-slot:activator="{ props }">
        <v-btn id="language" v-bind="props"  class="mx-2">
          <span   class="text-white">{{ $i18n.locale }}</span>
          <v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item style="cursor: pointer"  @click="switchLang(locale.code)" v-for="locale in availableLocales" :key="locale.code">
          <v-list-item-title
            ><span
          
              :id="'switchLanguage' + locale.code"
              >{{ locale.code.toUpperCase() }}</span
            ></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  computed: {
    availableLocales() {
      window.test = this.$i18n
      return this.$i18n.locales.filter((i) => i !== this.$i18n.locale);
    },
  },
  methods: {
    switchLang(code) {
      this.$i18n.setLocale(code);
    },
  },
};
</script>