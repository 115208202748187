<template>
  <div id="app">
    <NuxtLoadingIndicator color="#69aa41"/>
    <s-loader/>
    <v-theme-provider>
        <NuxtLayout >
          <NuxtPage />
        </NuxtLayout>
    </v-theme-provider>
  </div>

</template>
<script setup>
  const { initdone} = useAuth()
  const router = useRouter()

  const { decr } =  useLoader(true)

  var name = computed(() => {
      const matched = router.currentRoute.value.matched
    var found = matched.length && matched[0].components.default
    return found ?  toCap(matched[0].components.default.__name) : undefined 
  })

 
  useHead({
    title:  name
  })
 
onMounted(async () => {
    await initdone()
    decr()
})
</script>
