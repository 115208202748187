<template>
    <s-loader-mini :loading="isLoading" />
</template>

<style lang="scss">
.loader {
    top: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,1);
    z-index: 1021;
}
</style>
<script setup>
  const props = defineProps({
      loading: {
          type: Boolean,
          default: false
      }
  })
  const { isLoading, incr } = useLoader()
  if(props.loading) {
      incr()
  }
</script>